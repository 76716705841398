@import './common';
//
//#contact-us {
//  background-image: url('../images/topography 2.svg');
//}
.background {
  background-image: url('../images/topography 2.svg');
}

.contact-us {
  padding-right: 15px;
  padding-left: 15px;
}

.container-fluid {
  width: 100%;

  margin-right: auto;
  margin-left: auto;
}

.contact-us-header {
  text-align: center;
  padding: 30px 0px 15px 0;
}


.row {
  display: flex;
  padding-top: 15px;
  padding-bottom: 60px;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.row-item {
  padding: 10px 0;
  @include desktop {
    padding: 0;
    width: 33%;
  }

}


.row-item-heading, .row-item-icon, .row-item-content {
  text-align: center;
}


