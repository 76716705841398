$gold: #948A54;
$red: #CE010C;
$black: #333;
$white: #FFFFFF;

* {
  box-sizing: border-box;
}

body, form, fieldset, ol, ul, li, h1, h2, h3, h4, h5, h6, p, button {
  margin:0;
  padding:0;
  font-family: "Quicksand",sans-serif;
}

@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500');

html {font-size: 100%;} /*16px*/

body {
  background: white;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  line-height: 1.3;
  color: #000000;
}

p {margin-bottom: 1rem;}

h1, h2, h3, h4, h5 {
  margin: 1rem 0 1rem;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  line-height: 1.3;
  color: $gold !important;
}

h1 {
  margin-top: 0;
  font-size: 2.488rem;
}

h2 {font-size: 2.074rem;}

h3 {font-size: 1.728rem;}

h4 {font-size: 1.44rem;}

h5 {font-size: 1.2rem;}

small, .text_small {font-size: 0.833rem;}

img {
  border:0; /* kills Gecko bug when img's are placed inside links */
  vertical-align:bottom; /* set vertical align to bottom for IE */
}

html {
  height:100%;
  //overflow:auto; /* triggers 100% height in Opera 9.5 */
  //overflow-y:scroll; /* creates a horizontal scrollbar, for a more consistent look & feel */
}
body {
  height:100%; /* helps make height 100% */
  background:#FDFDFD;
  color:#333;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin ipad {
  @media (min-width: 769px) and (max-width: 1125px){
    @content;
  }
}

@mixin desktop {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin desktop-service {
  @media (min-width: 1125px) {
    @content;
  }
}


@mixin tablet-portrait {
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}


html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  -webkit-text-decoration: underline rgba(0, 0, 0, 0);
  text-decoration: underline rgba(0, 0, 0, 0);
  text-underline-offset: 5px;
  text-decoration-thickness: 3px;
  -webkit-transition: -webkit-text-decoration-color 300ms;
  transition: -webkit-text-decoration-color 300ms;
  -o-transition: text-decoration-color 300ms;
  transition: text-decoration-color 300ms;
  transition: text-decoration-color 300ms, -webkit-text-decoration-color 300ms;
  cursor: pointer;
}

.btn {
  border-radius: 5px!important;
  font-size: 16px;
  font-weight: 700;
  height: 65px;
  min-width: 193px;
  line-height: 28px;
  padding: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: .4s cubic-bezier(.39,.575,.565,1);
  -o-transition: .4s cubic-bezier(.39,.575,.565,1);
  transition: .4s cubic-bezier(.39,.575,.565,1);
  -webkit-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  &-primary {
    border-color: $black;
    background-color: transparent;
  }
  &:hover {
    -webkit-transform: scale(1.05)!important;
    -ms-transform: scale(1.05)!important;
    transform: scale(1.05)!important;
  }
}
