@import './common';

.container {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
}

.services-header {
  text-align: center;
  padding: 30px 0px 15px 0;
}

.services-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @include desktop-service {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

  }
}

.service-item {
  text-align: center;
  border:2px solid $gold;
  border-radius: 3px!important;
  padding: 15px;
  @include desktop-service{
    width: 32%;
  }
  margin: 15px 5px;
  //box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
  // @todo: add shadow
}




// SERVICES 2

.service-item-2 {
    position: relative;
    padding: 30px 0;
}
.service-overlap {
  padding: 1.25em;
  top: 80%;
  z-index: 10;
  right: 5%;
  @include desktop-service {
    left: 35%;
    position: absolute;
    height: inherit;
    top: 10%;
    &-right {
      left: 2.5%
    }
  }
}

.service-image {
  @include desktop-service {
  margin-left: 2.5%;
  max-width: 35%;
  height: 35%;
    &-right {
      margin-left: 62.5%;
      margin-right: 2.5%;
    }
  }
}
.responsive {
  width: 100%;
  height: auto;
}

.service-info {
  background-color: $gold;
  color: $white;
  @include desktop-service {
    width: 90%;
    width: 62.5%;
    opacity: 0.8;
    height: 80%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
  }
  padding: 45px;
}

.service-item-3 {
  text-align: center;
  //border:2px solid $gold;
  //border-radius: 3px!important;
  //padding: 15px;
  @include desktop-service{
    width: 25%;
  }
  margin: 15px 5px;
  //box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
  // @todo: add shadow
}
.service-item-header > h3 {
  color: $white !important;
  display: flex;
  align-items: center;
}

.service-item-container {
  border:2px solid $gold;
  -moz-border-radius-bottomleft: 3px!important;
  -moz-border-radius-bottomright: 3px!important;
  padding: 30px;
}

h3 span { background: #fff; display: inline-block; padding: 1px 15px; }

.mySpan {
  transform: none;
}
