@import './common';


#gallery {
  margin-bottom: 15px;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include desktop {
    justify-content: space-between;
    // flex-wrap: nowrap;
  }
  &-header {
    padding: 2.5% 0px;
    text-align: center;
    & >h1 {
      margin: 0;
    }
  }
  &-item {
      margin: 5px;
        width: 40%;
      @include desktop {
        width: 33%;
        margin: 0px;
        padding: 5px;
      }

    img.hover-shadow {
      transition: 0.3s;
    }

    .hover-shadow:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      filter: brightness(70%);
      -webkit-filter: brightness(70%);
      -moz-filter: brightness(70%);

    }
  }
}
.lightbox, .control-button {
  &:hover{
    cursor: pointer;
  }
  border: none;
}
.control-button {
  background-color: transparent;
  color: hsla(0,0%,93.3%,0.9);
}

.control {
  opacity: 0.8;
}

#lightbox-img {
  max-width: 50vw;
  @include desktop {
    max-width: 90vw;
  }
  object-fit: cover;
}

#lightbox {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-around;
  vertical-align: bottom;
  transition: all 5s;
}

.of {
  overflow: auto!important;
  height: auto;
}
