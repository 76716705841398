@import "./_common.scss";
header {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @include desktop {
    padding-bottom: 150px;
  }
}


//NAVBAR STYLE
.navbar {
  display: flex;
  background: white;
  justify-content: space-between;
  align-items: center;
  @include desktop {
    height: 150px;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  opacity: 0.95;
  padding: 0.5rem 1rem!important;
  transition: height 0.3s;
  -webkit-transform:translateY(0);
  transform:translateY(0);
  -webkit-box-shadow:0 12px 34px -11px rgba(65,62,101,.1);
  box-shadow:0 12px 34px -11px rgba(65,62,101,.1);
}


.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 25px;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-navbar {
  height: 110px;
  transition: height 0.3s;
}

img {
  max-width: 100%;
  max-height: 100%;
}


.nav-options {
  padding: 0 32px;
  display: flex;
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
  list-style-type: none;
  justify-content: flex-end;
}


.nav-option {
  color: $black;
  text-transform: capitalize;
  a, button {
    font-weight: 500;
    font-size: 16px;
  }
  button {
    -webkit-text-decoration: underline rgba(0, 0, 0, 0);
    text-decoration: underline rgba(0, 0, 0, 0);
    text-underline-offset: 2px;
    text-decoration-thickness: 3px;
    transition: text-decoration-color 300ms;
    padding: 0;
    border: none;
    background: none;
  }
  button:hover {
    -webkit-text-decoration-color: $black;
    text-decoration-color: $black;
    cursor: pointer;
  }
}

.selected {
  text-decoration: underline !important;
  text-decoration-color: $black !important;
  text-underline-offset: 2px !important;
  text-decoration-thickness: 3px !important;
}

a {
  -webkit-text-decoration: underline rgba(0, 0, 0, 0);
  text-decoration: underline rgba(0, 0, 0, 0);
  text-underline-offset: 2px;
  text-decoration-thickness: 3px;
  transition: text-decoration-color 300ms;
}

a:hover {
  -webkit-text-decoration-color: $black;
  text-decoration-color: $black;
}


.mobile-option {
  display: none;
}

.mobile-menu {
  display: none;
}

.menu {
  padding: 10px;
}

.categories-options, .language-options {
  padding: 10px;
}



@include mobile {

  .navbar {
    position: static;
    padding: 0 !important;
    justify-content: space-between;
  }

  .menu {
    display: flex;
    width: 100%;
    height: 50vh;
    position: absolute;
    top: 80px;
    right: 100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    justify-content: center;
  }

  .logo-navbar {
    height: 60px;
  }

  .nav-options {
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .menu.active {
    background: $white; // todo: change it
    left: 0;
    opacity: 1;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .icons-options {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .menu-icon {
    width: 45px;
    height: 45px;
  }
  .nav-option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
    padding: 16px 0px;
  }
  .mobile-menu {
    display: block;
    color: $white;
    padding-right: 50px;
    button {
      border-radius: 10px;
      border: 1px solid $black;
      background-color: $white;
      fill: $black;
    }
  }
}


.menu-options {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.flag-icon {
  font-size: 30px;
}


// Navbar
@include desktop {
  .nav__shrink {
    height: 90px;
    transition: all 0.5s ease;
    .logo-navbar {
      height: 60px;
    }
    .menu {
      padding: 0 !important;
    }
  }
}

