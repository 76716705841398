@import './_common.scss';

.error {
  height: 80vh;
  background-color: transparent;
}

//.error-content {
//  height: 80vh;
//}

.error-content_404 {
  color: $red;
  display: flex;
  justify-content: center;
  align-items: center;
  &>h2 {
    color: $red !important;
    font-size: 3rem;
    line-height: 100px;
    font-weight: bold;
  }
}

.flex-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.under-construction_content {
  display: flex;
  flex-direction: column;
}

.error-content_message {
  display: flex;
}

.error-content_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  &>h2, a, p{
    text-align: center;
  }
}
