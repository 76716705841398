@import './common';

.about-us {
  background: var(--soft-gray);
  display: flex;
  height: 100vh;
  align-items: center;
  @include mobile  {
    flex-direction: column;
  }
  @include desktop {
    height: auto;
    position: relative;
  }
}

.row-about-us {
  display: flex;
  padding-top: 15px;
  padding-bottom: 60px;
  @include desktop {
    justify-content: flex-end!important;
    flex-wrap: wrap;
  }
}

.row-about-us-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
}
.row-content-header {
  text-align: center;
}
.row-content-button {
  text-align: center;
}

.row-content-button {
 padding-top: 15px;
}


.row-about-us-2 {
  display: flex;
  height: 100%;
  flex-direction: column;
  @include mobile {
    margin-bottom: 15px;
  }
  @include desktop {
    flex-direction: row;
  }

  @include tablet-portrait {
    flex-direction: column;
    padding-bottom: 30px;
  }
}
.right {
  height: 100%;
  width: 100%;
  &>video {
    height: 100%;
    width: 100%;
  }
  @include desktop {
    width: 66.66%;
  }
  @include tablet-portrait {
    width: 100%;
  }
}

.left {
  display: flex;
  width: 100%;
  @include desktop {
    width: 33.33%;
  }
  @include tablet-portrait {
    width: 100%;
  }
}


.about-us-container {
  height: 100%;
}
