@import './common';

footer {
  background-color: $gold;
  display: flex;
  align-items: center;
  padding: 32px 10%;
  flex-wrap: wrap;
}


.logos-container {
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
  }
  justify-content: space-between;
}

.logo {
  height: 60px;
  //max-width: 33%;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.information, .socials-rights {
  @include desktop {
    flex-basis: 50%;
  }
}

.information-item {
  display: flex;
  align-items: center;
  > div {
    padding: 6px 6px 6px 0px;
  }
}

.socials {
  display: flex;
  flex-direction: row;
  @include desktop {
    justify-content: flex-end;
  }
  a {
    display: inline-block;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    svg path {
      transition: fill 200ms;
    }

    &:not(:first-child){
      margin-left: 10px;
    }

    svg path.mustfill {
      fill: $black;
    }

      svg:hover {
        fill: $white;
        transition: fill 200ms;
      }
  }
}

.rights {
  display: flex;
  flex-direction: column;
  @include desktop {
    align-items: flex-end;
  }
  a {
    text-decoration: underline rgba(0, 0, 0, 0);
    text-underline-offset: 2px;
    text-decoration-thickness: 3px;
    transition: text-decoration-color 300ms;
  }

  a:hover {
    text-decoration-color: $black;
  }
}

.footer-container {
  padding: 16px 0;
}


.logos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include desktop {
    width: 50%;
    justify-content: space-around;
  }
}


.footer-instagram{
  margin-right: 10px;
}
.footer-twitter{
  margin-left: 5px;
  margin-right: 5px;
}
.footer-youtube{
  margin-left: 10px;
}
